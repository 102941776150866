import { ROLLUP_VIEW_TYPES } from './rollups.interface';

export const emptyTotals = {
  current_budget: 0,
  contracts: 0,
  total_committed: 0,
  actuals: 0,
  forecasts_to_complete: 0,
  complete: 0,
  total_committed_vs_current_budget: 0,
  actuals_vs_total_committed: 0,
  total_invoiced: 0,
  forecast_modifications: 0,
};

export const viewTitleMap = {
  [ROLLUP_VIEW_TYPES.PROJECTS]: 'Projects',
  [ROLLUP_VIEW_TYPES.BUDGET_LINE_ITEMS]: 'Budget Lines',
  [ROLLUP_VIEW_TYPES.ACCOUNTS]: 'Accounts',
  [ROLLUP_VIEW_TYPES.TAGS]: 'Budget Tags',
};
