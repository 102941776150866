export const iconDropdown = (isDropdownShown: boolean) => {
  return {
    position: 'p-input-icon-right',
    icon: isDropdownShown ? 'dropdown-icon opened' : 'dropdown-icon closed',
  };
};

export enum PROGRESS_BAR_PERCENT_COLORS {
  OVER_COMMITTED, // color red light
  COMPLETE, //  color accent
  DEFAULT, // color secondary
}

export const CAPEX_ID = 1;
export const OPEX_ID = 2;

export const INTERCOM_ID = 'rr1klq7i';
